// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://webservice.infitec.es/restgobernantawa',
  // baseUrl:'http://localhost:40474',
  rolAdmin:'1',
  rolCliente:'6',
  estadoPasarelaPendiente:'PAS/PEN',
  estadoPasarelaOk:'PAS/OK',
  estadoPasarelaError:'PAS/ERROR',
  // productoId:'35146'

  //SubTipoPedidoVenta
  pagoTarjeta:'P/PASARELA-ENVIADO',
  pagoTienda:'P/LOCAL-INICIADO',
  pagoEnviado:'P/ENVIADO',

  //formasEntrega
  recogerTienda:'R',
  envioDomicilio:'D',

  //formasPago
  local:1,
  tarjeta:2,
  transferencia:3,

  //Cookies
  cookieCheckinOnlineNavegador:"CookieCheckinOnlineNavegador", //Esta es la cookie del navegador
  cookieCheckinOnline:"CookieCheckinOnline", //cookie para guardar idReserva y datos del establecimiento

  //tipoReserva
  reservaHotelPC:1,

  INT_PRE_SUF_SECURITY_STAMP:5,
  ClaveSecreta:"infitecDelnortE",
  dataAdmin:"showData"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
