import { Component, OnInit } from '@angular/core';
import { Establecimiento } from 'src/app/models/establecimiento';
import { Funciones } from 'src/app/resources/Funciones';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDialog } from '@angular/material';
import { RestService } from 'src/app/services/rest.service';
import { VentanaNotificacionComponent } from '../ventana-notificacion/ventana-notificacion.component';
import { CookieReserva } from 'src/app/models/cookie-reserva';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-checkin-online',
  templateUrl: './checkin-online.component.html',
  styleUrls: ['./checkin-online.component.scss']
})
export class CheckinOnlineComponent implements OnInit {

  showInfi=true;
  showError=false;
  strMensajeError:string;
  cookie:string;
  idReserva:any;
  miEstablecimiento:Establecimiento=new Establecimiento();

  miFunciones: Funciones = new Funciones();

  cookieCheckinOnline=environment.cookieCheckinOnline;
  // cookieNavegador=environment.cookieCheckinOnlineNavegador;
  misDatosCookieReserva:CookieReserva=new CookieReserva();
  // nomCookie:any;
  datoAux:string=undefined;
  isAdmin=false;
  strReservaCerrada="N";

  constructor(private router: Router,private route: ActivatedRoute,private cookieService: CookieService,private dialog: MatDialog,private miservicio:RestService) {
    this.route.queryParams.subscribe(params=>{
      this.cookie=params["coo"];
      this.idReserva=params["id"];
      this.datoAux=params[environment.dataAdmin]
    })
   }

  ngOnInit() {
    // console.log(this.datoAux)
    if(this.datoAux!=undefined){
      if(JSON.parse(this.datoAux)){
        this.isAdmin=true;
        // this.strReservaCerrada="S";
      }
    }
    // console.log(this.cookie)
    
    // console.log(this.idReserva)

    this.getDatos();
  }

  removeCookies(){
    this.cookieService.delete(this.cookieCheckinOnline+this.miEstablecimiento.intId,'',window.location.hostname);
  }

  getDatos(){
    this.idReserva=this.miFunciones.DesencriptaUsuarioPassword(this.idReserva)
    //Recupero datos establecimiento
    this.miservicio.getEst(this.cookie).subscribe(datos=>{
      if(datos.miRespuesta.booOk){
        this.removeCookies();
        // console.log(datos)
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        
        // this.nomCookie=this.miFunciones.encryptData(this.miEstablecimiento.intId.toString())
        //Relleno los datos que guardo en la cookie
        this.misDatosCookieReserva.cookieP=this.cookie;
        // this.misDatosCookieReserva.logo=this.miEstablecimiento.strLogo;
        this.misDatosCookieReserva.nombre=this.miEstablecimiento.strNombre;
        this.misDatosCookieReserva.descripcion=this.miEstablecimiento.strDescripcion;
        this.misDatosCookieReserva.eslogan=this.miEstablecimiento.strEslogan;
        this.misDatosCookieReserva.email=this.miEstablecimiento.strEmail;
        // this.misDatosCookieReserva.telefono1=this.miEstablecimiento.strTelefono1;
        // this.misDatosCookieReserva.telefono2=this.miEstablecimiento.strTelefono2;
        // this.misDatosCookieReserva.direccion=this.miEstablecimiento.strDireccion;
        // this.misDatosCookieReserva.cp=this.miEstablecimiento.strCp;
        // this.misDatosCookieReserva.poblacion=this.miEstablecimiento.strPoblacion;
        // this.misDatosCookieReserva.provincia=this.miEstablecimiento.strProvincia;
        this.misDatosCookieReserva.pais=this.miEstablecimiento.strPais;
        this.misDatosCookieReserva.condicionesGenerales=this.miEstablecimiento.strCondicionesGenerales;
        this.misDatosCookieReserva.politicaCancelaciones=this.miEstablecimiento.strPoliticaCancelaciones;
        this.misDatosCookieReserva.politicaCookies=this.miEstablecimiento.strPoliticaCookies;
        this.misDatosCookieReserva.politicaPrivacidad=this.miEstablecimiento.strPoliticaPrivacidad;
        this.misDatosCookieReserva.strPrecioCheckin=this.miEstablecimiento.strPrecioCheckin;
        this.misDatosCookieReserva.strMuestraTipoHabitacion=this.miEstablecimiento.strMuestraTipoHabitacion;

        //Doy formato
        if(this.misDatosCookieReserva.telefono1!=null && this.misDatosCookieReserva.telefono1!=""){
          this.misDatosCookieReserva.telefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.misDatosCookieReserva.telefono2!=null && this.misDatosCookieReserva.telefono2!=""){
          this.misDatosCookieReserva.telefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.misDatosCookieReserva.direccion!=null && this.misDatosCookieReserva.direccion!=""){
          this.misDatosCookieReserva.direccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.misDatosCookieReserva.poblacion!=null && this.misDatosCookieReserva.poblacion!=""){
          this.misDatosCookieReserva.poblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.misDatosCookieReserva.cp!=null && this.misDatosCookieReserva.cp!=""){
          this.misDatosCookieReserva.cp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.misDatosCookieReserva.provincia!=null && this.misDatosCookieReserva.provincia!=""){
          this.misDatosCookieReserva.provincia=" - " + this.miEstablecimiento.strProvincia;
        }

        //Recupero datos reserva
        this.miservicio.getReserva(this.cookie,this.idReserva,"",0,"",environment.reservaHotelPC).subscribe(data=>{
          // console.log(data.miListReservas[0].strWebCerrada)
          if(data.miRespuesta.booOk){
            this.misDatosCookieReserva.idReserva=data.miListReservas[0].intId;
            // let boolValue = (data.miListReservas[0].strWebCerrada === "S");
            this.misDatosCookieReserva.booReservaCerrada=(data.miListReservas[0].strWebCerrada === "S");

            //Compruebo si la cookie existe
            let IsCookieExists:boolean=this.cookieService.check(this.cookieCheckinOnline+this.miEstablecimiento.intId);
            // console.log(IsCookieExists)
            if(!IsCookieExists){
              // console.log(this.misDatosCookieReserva)
              this.cookieService.set(this.cookieCheckinOnline+this.miEstablecimiento.intId,JSON.stringify(this.misDatosCookieReserva),4,'',window.location.hostname);
            }else{
              this.removeCookies();
              this.cookieService.set(this.cookieCheckinOnline+this.miEstablecimiento.intId,JSON.stringify(this.misDatosCookieReserva),4,'',window.location.hostname);
            }

            // console.log(this.misDatosCookieReserva)
            // console.log(this.miEstablecimiento.intId.toString())

            // let valor=this.miFunciones.encryptData(this.miEstablecimiento.intId.toString())

            // console.log(valor)
            // console.log(this.miFunciones.decryptData(valor))

            setTimeout(()=>{
              let extra: NavigationExtras = {
                queryParams: {
                  'id':this.miFunciones.encryptData(this.miEstablecimiento.intId.toString())
                }
              }
              if(this.isAdmin){
                if(data.miListReservas[0].strWebCerrada==="N"){
                  this.strMensajeError="El cliente aun no ha realizado el checkin online. No hay datos para visualizar.";
                  this.windowNotif("El cliente aun no ha realizado el checkin online.","No hay datos para visualizar.",false,true,false);
                }else{
                  this.router.navigate(['admin/data-reserva'], extra)
                }
              }else{
                this.router.navigate(['inicio/resumen-reserva'], extra)
              }
              
            },1000)

          }else{
            this.strMensajeError=data.miRespuesta.strMensaje;
            this.windowNotif(data.miRespuesta.strMensaje,"",false,true,false);
          }
        })
      }else{
        this.strMensajeError=datos.miRespuesta.strMensaje;
        this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false)
      }
    })
  }

  windowNotif(mesaje,mesaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mesaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
    dialogRef.afterClosed().subscribe(response=>{
      this.showInfi=false;
      this.showError=true;
    }
    )
  }

}
