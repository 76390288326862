import { Component, OnInit, ViewChild, ElementRef, Renderer2, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TakePictureComponent } from '../take-picture/take-picture.component';


@Component({
  selector: 'app-foto',
  templateUrl: './foto.component.html',
  styleUrls: ['./foto.component.scss']
})
export class FotoComponent implements OnInit {

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  // @ViewChild('canvas2', { static: true }) canvas2: ElementRef;
  videoWidth = 0;
  videoHeight = 0;
  constraints = {
    video: {
        facingMode: "environment",
        // width: { ideal: 4096 },
        // height: { ideal: 2160 }
    }
  };
  
  // strFotos:objFotos;
  strBase64Foto1:string;
  strBase64Foto2:string;
  showSpinner=false;

  WIDTH = 640;
  // HEIGHT = 480;
  HEIGHT = 450;
  error: any;
  isError=false;

  isTomarFoto=false;
  tomaFoto=false;
  showActions=true;
  imageSrc: string = '';
  showImgUpload=false;
  subeImg=false;
  files: any[] = [];

  showImg=false;

  constructor(private renderer: Renderer2,private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) private data: any,private dialog: MatDialog,
              private compressImage: NgxImageCompressService) { }

  ngOnInit() {
    // this.showSpinner=true;
    // this.startCamera();
    // this.setupDevices();
    // console.log(this.data)
    // console.log(this.canvas.nativeElement.toDataURL());
  }

  // async ngAfterViewInit() {
  //   await this.setupDevices();
  // }

  startCamera() {
    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        // navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
        // navigator.mediaDevices.getUserMedia({ video: true }).then(this.attachVideo.bind(this)).catch(this.handleError);
        navigator.mediaDevices.getUserMedia({video: {facingMode: {exact: "environment"}}}).then(this.attachVideo.bind(this)).catch(this.handleError);
    } else {
        alert('No se puede abrir la camara.');
    }
  }

  attachVideo(stream) {
    this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
    this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
        this.videoHeight = this.videoElement.nativeElement.videoHeight;
        this.videoWidth = this.videoElement.nativeElement.videoWidth;
    });
  }

  capture() {
    this.showSpinner=true;
    this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
    this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
    this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);

    this.strBase64Foto1=this.canvas.nativeElement.toDataURL();
    this.thisDialogRef.close(this.strBase64Foto1);
  }

  // capture2() {
  //   this.renderer.setProperty(this.canvas2.nativeElement, 'width', this.videoWidth);
  //   this.renderer.setProperty(this.canvas2.nativeElement, 'height', this.videoHeight);
  //   this.canvas2.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);

  //   this.strBase64Foto2=this.canvas2.nativeElement.toDataURL();
  // }

  handleError(error) {
      console.log('Error: ', error);
  }

  // btnGuardar(){
  //   // console.log(this.strBase64Foto1)
  //   // console.log(this.strBase64Foto2)
  //   this.thisDialogRef.close({"foto1":this.strBase64Foto1, "foto2":this.strBase64Foto2});
  // }

  // cancelar(){
  //   this.thisDialogRef.close();
  // }



  /************************************* */

  tomarFoto(){
    // this.showSpinner=true;
    // this.tomaFoto=true;
    this.showActions=false;
    // this.setupDevices();

    let dialogRef = this.dialog.open(TakePictureComponent, {
      width:'600px',
      data:"foto1"
    });

    dialogRef.afterClosed().subscribe(result =>{
      // console.log(result)
      if(result!=undefined){
        result = result.substring(
          result.lastIndexOf(",")+1, 
          result.length
        );

        this.thisDialogRef.close(result);
      }   
      else{
        this.thisDialogRef.close(result);
      }
      
    })
  }

  cargarImagen(){
    this.showActions=false;
    this.showImgUpload=true;
    this.tomaFoto=true;
    this.subeImg=true;
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          // video: true
          video:{facingMode:'environment'}
        });
        if (stream) {
          // console.log('LLEGOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO')
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
          this.error = null;
          this.showSpinner=false;
          this.isError=false;
          this.showImg=true;
        } else {
          this.error = "No se puede abrir la camara.";
          this.showSpinner=false;
          this.isError=true;
          this.showImg=false;
        }
      } catch (e) {
        // this.error = e;
        this.error = "No se puede abrir la camara.";
        this.showSpinner=false;
        this.isError=true;
        this.showImg=false;
      }
    }
  }

  capture2() {
    this.drawImageToCanvas(this.videoElement.nativeElement);
    // this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    // console.log(this.canvas.nativeElement.toDataURL("image/png"))

    this.thisDialogRef.close(this.canvas.nativeElement.toDataURL("image/png"));
    // this.isCaptured = true;
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }


  recuperaImgFoto1(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();
    var pattern = /image-*/;
    if (!file.type.match(pattern)) {
      alert('Formato no válido.');
      return;
    }
    reader.onload = this._handleReaderLoaded1.bind(this);
    reader.readAsDataURL(file);
  }
  
  _handleReaderLoaded1(e) {
    let reader = e.target;
    this.imageSrc = reader.result;  
  }

  uploadIMG(){
    if(this.imageSrc!=""){
      this.imageSrc = this.imageSrc.substring(
        this.imageSrc.lastIndexOf(",")+1, 
        this.imageSrc.length
      );
      this.thisDialogRef.close(this.imageSrc);
    }
  }


  /********** SUBIDA IMAGENES ***********/

  fileBrowseHandler(e) {
    // console.log(this.files)
    this.showImg=false;
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var reader = new FileReader();

    //documento subido por tecnico
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.prepareFilesList(e.target.files);

    // // Extension fichero
    // let encuentaPunto=false;
    // for(var i = file.name.length-1; i > 0; i--){
    //   if(!encuentaPunto){
    //     if(file.name[i]==="."){
    //       encuentaPunto=true;
    //       var x=i;
    //     }
    //   }
    // }

    // let extensionFichero = "." + file.name.substring(file.name.length, x+1);

    // this.miresponsePopUp.strNombre+=this.miFunciones.generaPin()+extensionFichero;
  }

  uploadFilesSimulator(index: number) {
    // console.log(this.files[index].progress)
    setTimeout(() => {
      if (index === this.files.length) {
        // this.thisDialogRef.close(this.imageSrc)
        this.showImg=true;
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
            // this.showImg=true;
          }
        }, 200);
      }

      // let booUploadImg=false;
      // if(this.files.length>0){
      //   this.files.splice(0,this.files.length);
      //   booUploadImg=true;
      // }
      // else{
      //   booUploadImg=true
      // }

      // if(booUploadImg){
      //   const progressInterval = setInterval(() => {
      //     if (this.files[index].progress === 100) {
      //       clearInterval(progressInterval);
      //       // this.uploadFilesSimulator(index + 1);
      //     } else {
      //       // this.files[index].progress += 5;
      //     }
      //   }, 200);
      // }

    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      if(this.files.length>0){
        this.files.splice(0,this.files.length);
        this.files.push(item);
      }
      else{
        this.files.push(item);
      }
      
    }
    // console.log(this.files.length)
    this.uploadFilesSimulator(0);
  }

  formatBytes(bytes, decimals?:any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  _handleReaderLoaded(e) {
    // console.log(e)
    this.compressImage.compressFile(e.target.result, -1, 50, 50).then(
      result => {
        
        result = e.target.result.substring(
          e.target.result.lastIndexOf(",")+1, 
          e.target.result.length
        );
        // console.log(this.imageSrc)
        // this.showImg=true;

        this.imageSrc=result;
      }
    );
  }

  close(){
    this.thisDialogRef.close();
  }

}



// export interface objFotos {
//   strBase64Foto1:any;
//   strBase64Foto2:any; 
// }
