import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EstablecimientoResult } from '../models/EstablecimientoResult';
import { Respuesta } from '../models/Respuesta';
import { HotelReservaResult } from '../models/hotel-reserva-result';
import { ClienteResult } from '../models/cliente-result';
import { Cliente } from '../models/cliente';
// import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }
  
  public url: string = environment.baseUrl;  
  headers = new HttpHeaders().set('Content-Type','application/json');

//   private strToken=this.storageService.getCurrentToken();

//   httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer ' + this.strToken  })
//   };

  public appDrawer: any;

  //datos establecimiento
  getEst(cookie):Observable<EstablecimientoResult>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/Establecimiento/general?'+dato;
  
    return this.http.get<EstablecimientoResult>(urlCompleta,{headers:this.headers});
  }

  //RecuperaLogo
  getLogo(cookie):Observable<Respuesta>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/Establecimiento/logo?'+dato;
  
    return this.http.get<Respuesta>(urlCompleta,{headers:this.headers});
  }

  //datos reserva
  getReserva(cookie,intId,strLocalizador,intAgrupadoId,strCerrada,intTipo):Observable<HotelReservaResult>{
    let dato='strCookie='+cookie+'&intId='+intId+'&strLocalizador='+strLocalizador+'&intAgrupadoId='+intAgrupadoId+'&strCerrada='+strCerrada+'&intTipo='+intTipo;
    let urlCompleta=this.url+'/api/HotelReserva/checkinonline?'+dato;
  
    return this.http.get<HotelReservaResult>(urlCompleta,{headers:this.headers});
  }

  //cliente
  getClientes(cookie,intId,intReservaId,intClienteId,strCerrada,intTipo):Observable<ClienteResult>{
    let dato='strCookie='+cookie+'&intId='+intId+'&intReservaId='+intReservaId+'&intClienteId='+intClienteId+'&strCerrada='+strCerrada+'&intTipo='+intTipo;
    let urlCompleta=this.url+'/api/HOTELReservaCliente/cliente?'+dato;
  
    return this.http.get<ClienteResult>(urlCompleta,{headers:this.headers});
  }

  //insertaCliente
  postClientes(cookie,miCliente:Cliente):Observable<ClienteResult>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/HOTELReservaCliente?'+dato;
    let body=JSON.stringify(miCliente);
  
    return this.http.post<ClienteResult>(urlCompleta,body,{headers:this.headers});
  }

  putClientes(cookie,miCliente:Cliente):Observable<ClienteResult>{
    let dato='strCookie='+cookie;
    let urlCompleta=this.url+'/api/HOTELReservaCliente?'+dato;
    let body=JSON.stringify(miCliente);
  
    return this.http.put<ClienteResult>(urlCompleta,body,{headers:this.headers});
  }

  //cierra CheckinOnline
  putCheckinOnline(cookie,intId):Observable<Respuesta>{
    let dato='strCookie='+cookie+'&intId='+intId;
    let urlCompleta=this.url+'/api/HotelReserva?'+dato;
  
    return this.http.put<Respuesta>(urlCompleta,{headers:this.headers});
  }

  /********** Exportar a EXCEL *************/
/*
  exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
*/
  /********** Menu, Sub items *************/

  public closeNav() {
    this.appDrawer.close();
  }

  public openNav() {
    this.appDrawer.open();
  }

}
