import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieReserva } from 'src/app/models/cookie-reserva';
import { MatDialog, MatDatepickerInputEvent, DateAdapter } from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'src/app/services/rest.service';
import { Funciones } from 'src/app/resources/Funciones';
import { VentanaNotificacionComponent } from '../ventana-notificacion/ventana-notificacion.component';
import { Cliente } from 'src/app/models/cliente';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FotoComponent } from '../foto/foto.component';

@Component({
  selector: 'app-formulario-huesped',
  templateUrl: './formulario-huesped.component.html',
  styleUrls: ['./formulario-huesped.component.scss']
})
export class FormularioHuespedComponent implements OnInit {

  cookieCheckinOnline=environment.cookieCheckinOnline;
  misDatosCookieReserva:CookieReserva=new CookieReserva();
  miFunciones:Funciones=new Funciones();
  idEst:any;
  idCliente:any;
  strLogo:string;
  showSpinner = true;

  huesped:Cliente=new Cliente();
  formNewHuesped: FormGroup;
  mensajeError:string;
  existError=false;

  submitted=false;
  dniValido=false;
  dniError=false;
  strTituloFormulario:string;
  miFechaNacimientoNueva:any;
  miFechaExpedidoNueva:any;

  btnInsert=false;
  btnEdit=false;
  listTipoSexo:listSexos[]=[];
  listTipoDocumento:listDodumentos[]=[];

  // imageSrc: string = '';
  // imageSrc2: string = '';

  strImgVisualizar:string='';

  constructor(private dialog: MatDialog,private router:Router,private cookieService: CookieService,private route: ActivatedRoute,private miservicio:RestService,
    private _formBuilder: FormBuilder,public datepipe: DatePipe, private dateAdapter: DateAdapter<Date>) {
      this.dateAdapter.setLocale('es');
      this.route.queryParams.subscribe(params=>{
        this.idEst=params["id"];
        this.idCliente=params["data"];
      })
   }

  ngOnInit() {
    // console.log(this.huesped.strBase64Foto1);
    
    let est=this.miFunciones.decryptData(this.idEst);
    let IsCookieExists:boolean=this.cookieService.check(this.cookieCheckinOnline+est);
    if(IsCookieExists){
      //Recupero los datos de la cookie
      this.misDatosCookieReserva=JSON.parse(this.cookieService.get(this.cookieCheckinOnline+est))
      // console.log(this.misDatosCookieReserva)
      //Doy formato
      // if(this.misDatosCookieReserva.telefono1!=null && this.misDatosCookieReserva.telefono1!=""){
      //   this.misDatosCookieReserva.telefono1=" - " + this.misDatosCookieReserva.telefono1;
      // }
      // if(this.misDatosCookieReserva.telefono2!=null && this.misDatosCookieReserva.telefono2!=""){
      //   this.misDatosCookieReserva.telefono2=" - " + this.misDatosCookieReserva.telefono2;
      // }
      // if(this.misDatosCookieReserva.direccion!=null && this.misDatosCookieReserva.direccion!=""){
      //   this.misDatosCookieReserva.direccion=" - " + this.misDatosCookieReserva.direccion;
      // }
      // if(this.misDatosCookieReserva.poblacion!=null && this.misDatosCookieReserva.poblacion!=""){
      //   this.misDatosCookieReserva.poblacion=" - " + this.misDatosCookieReserva.poblacion;
      // }
      // if(this.misDatosCookieReserva.cp!=null && this.misDatosCookieReserva.cp!=""){
      //   this.misDatosCookieReserva.cp=" - " + this.misDatosCookieReserva.cp;
      // }
      // if(this.misDatosCookieReserva.provincia!=null && this.misDatosCookieReserva.provincia!=""){
      //   this.misDatosCookieReserva.provincia=" - " + this.misDatosCookieReserva.provincia;
      // }

      // this.recuperoLogo()
    }else{
      this.windowNotif("Se ha producido un error.","Por favor vuelva a solicitar la URL.",false,true,false);
    }

    if(this.idCliente!=undefined){
      //Edit
      this.verDatosHuesped();
      this.huesped.intIdCliente=this.idCliente;
      this.btnEdit=true;
      if(this.misDatosCookieReserva.booReservaCerrada){this.btnEdit=false;}
    }
    else{
      //Insert
      this.recuperoLogo();
      // this.showSpinner=false;
      this.btnInsert=true;
      //Insercion
      this.strTituloFormulario="Nuevo huésped";
      this.huesped.intIdReserva=this.misDatosCookieReserva.idReserva;
    }

    this.listTipoSexo.push({strClave:"M", strValor:"Masculino"}, {strClave:"F", strValor:"Femenino"});
    this.listTipoDocumento.push({intTipo:1, strNombre:"N.I.F."}, {intTipo:2, strNombre:"N.I.E"}, {intTipo:3, strNombre:"Pasaporte"});

    this.formNewHuesped = this._formBuilder.group({
      nombre: [Validators.required],
      apellido1: [Validators.required],
      apellido2: [Validators.required],
      sexo: [Validators.required],
      fNacimiento: ['',Validators.required],
      documento:[Validators.required],
      nif: [Validators.required],
      fexpedido: [Validators.required],
      pais: [Validators.required],
      provincia: [Validators.required],
      cp: new FormControl('',Validators.compose([Validators.pattern('^[0-9_.+-]+$')]))
    })
  }

  verDatosHuesped(){
    this.miservicio.getClientes(this.misDatosCookieReserva.cookieP,0,this.misDatosCookieReserva.idReserva,this.idCliente,"",environment.reservaHotelPC).subscribe(datos=>{
      // this.showSpinner=false;
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.huesped=datos.miListReservasClientes[0];
        this.strTituloFormulario="Huésped " + datos.miListReservasClientes[0].strNombre + " " + datos.miListReservasClientes[0].strApellido1 + " " + datos.miListReservasClientes[0].strApellido2;
      }
      this.recuperoLogo()
    })
  }

  recuperoLogo(){
    this.miservicio.getLogo(this.misDatosCookieReserva.cookieP).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner = false;
      this.strLogo=datos.strValor;
      // console.log(this.strLogo)
    })
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    this.miFechaNacimientoNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.huesped.datFechaNacimiento=this.miFechaNacimientoNueva;
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.miFechaExpedidoNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.huesped.datFechaDniExpedido=this.miFechaExpedidoNueva;
  }

  btnInsertar(){
    this.submitted=true;
    // console.log(this.imageSrc2)

    if(this.formNewHuesped.valid){
      // console.log(this.huesped)
      this.showSpinner=true;
      switch(this.huesped.intTipoDocumento){
        case 3:
          //No valido 
          this.dniValido=true;
          this.dniError=false;
          break;

        default:
          this.nif();
          break;
      }
      if(this.dniValido){

        // if(this.imageSrc!=""){
        //   this.imageSrc = this.imageSrc.substring(
        //     this.imageSrc.lastIndexOf(",")+1, 
        //     this.imageSrc.length
        //   );
        //   this.huesped.strBase64Foto1=this.imageSrc;
        // }

        // if(this.imageSrc2!=""){
        //   this.imageSrc2 = this.imageSrc2.substring(
        //     this.imageSrc2.lastIndexOf(",")+1, 
        //     this.imageSrc2.length
        //   );
        //   this.huesped.strBase64Foto2=this.imageSrc2;
        // }
        // console.log(this.huesped.strBase64Foto1)
        if(this.huesped.strBase64Foto1!=undefined && this.huesped.strBase64Foto2!=undefined){
          this.miservicio.postClientes(this.misDatosCookieReserva.cookieP,this.huesped).subscribe(datos=>{
            // console.log(datos)
            this.showSpinner=false;
            if(datos.miRespuesta.booOk){
              // this.thisDialogRef.close(datos.miListReservasClientes);
              this.cancelar();
              this.existError=false;
            }
            else{
              this.mensajeError=datos.miRespuesta.strMensaje;
              this.existError=true;
            }
          })
        }
        else{
          this.showSpinner=false;
          this.windowNotif("Por favor adjunte las imágenes de su documento.","",false,true,false);
        }
      }else{
        this.showSpinner=false;
      }
    }
    else{
      this.windowNotif("Por favor revise que estén rellenados correctamente todos los campos obligatorios","(*)",false,true,false);
    }
  }

  btnEditar(){
    this.submitted=true;

    if(this.formNewHuesped.valid){
      this.showSpinner=true;
      switch(this.huesped.intTipoDocumento){
        case 3:
          //No valido 
          this.dniValido=true;
          this.dniError=false;
          break;

        default:
          this.nif();
          break;
      }

      if(this.dniValido){

        // if(this.imageSrc!=""){
        //   this.imageSrc = this.imageSrc.substring(
        //     this.imageSrc.lastIndexOf(",")+1, 
        //     this.imageSrc.length
        //   );
        //   this.huesped.strBase64Foto1=this.imageSrc;
        // }

        // if(this.imageSrc2!=""){
        //   this.imageSrc2 = this.imageSrc2.substring(
        //     this.imageSrc2.lastIndexOf(",")+1, 
        //     this.imageSrc2.length
        //   );
        //   this.huesped.strBase64Foto2=this.imageSrc2;
        // }

        // console.log(this.huesped.strBase64Foto1);
        // console.log(this.huesped.strBase64Foto2);

        this.miservicio.putClientes(this.misDatosCookieReserva.cookieP,this.huesped).subscribe(datos=>{
          // console.log(datos)
          this.showSpinner=false;
          if(datos.miRespuesta.booOk){
            // this.thisDialogRef.close(datos.miListReservasClientes);
            this.cancelar();
            this.existError=false;
          }
          else{
            this.mensajeError=datos.miRespuesta.strMensaje;
            this.existError=true;
          }
        })
      }
    }
    else{
      this.windowNotif("Por favor revise que estén rellenados correctamente todos los campos obligatorios","(*)",false,true,false);
    }
  }

  cancelar(){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEst
      }
    }

    this.router.navigate(['inicio/formulario'],extra)
  }

  abrirCamara(){
    let dialogRef = this.dialog.open(FotoComponent, {
      width:'600px',
      data:"foto1"
    });

    dialogRef.afterClosed().subscribe(result =>{
      // console.log(result)
      if(result!=undefined){
        result = result.substring(
          result.lastIndexOf(",")+1, 
          result.length
        );

        // console.log(result)
        this.huesped.strBase64Foto1=result;
      }      
    })
  }

  abrirCamara2(){
    let dialogRef = this.dialog.open(FotoComponent, {
      width:'600px',
      data:"foto2"
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        result = result.substring(
          result.lastIndexOf(",")+1, 
          result.length
        );
        
        this.huesped.strBase64Foto2=result;
      }      
    })
  }

  // recuperaImgFoto1(e) {
  //   var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  //   var reader = new FileReader();
  //   var pattern = /image-*/;
  //   if (!file.type.match(pattern)) {
  //     alert('Formato no válido.');
  //     return;
  //   }
  //   reader.onload = this._handleReaderLoaded1.bind(this);
  //   reader.readAsDataURL(file);
  // }
  
  // _handleReaderLoaded1(e) {
  //   let reader = e.target;
  //   this.imageSrc = reader.result;  
  // }

  // recuperaImgFoto2(e) {
  //   var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
  //   var reader = new FileReader();
  //   var pattern = /image-*/;
  //   if (!file.type.match(pattern)) {
  //     alert('Formato no válido.');
  //     return;
  //   }
  //   reader.onload = this._handleReaderLoaded2.bind(this);
  //   reader.readAsDataURL(file);
  // }
  
  // _handleReaderLoaded2(e) {
  //   let reader = e.target;
  //   this.imageSrc2 = reader.result;  
  // }

  nif() {
    var numero
    var letr
    var letra
  
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewHuesped.controls.nif.value.toUpperCase()) == true){
        numero = this.formNewHuesped.controls.nif.value.toUpperCase().substr(0,this.formNewHuesped.controls.nif.value.toUpperCase().length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = this.formNewHuesped.controls.nif.value.toUpperCase().substr(this.formNewHuesped.controls.nif.value.toUpperCase().length-1,1);
        numero = numero % 23;
        letra='TRWAGMYFPDXBNJZSQVHLCKET';
        letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        this.dniError=true;
        this.showSpinner=false;
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         this.dniError=false;
         return this.dniValido=true;
       }
    }else{
      this.dniError=true;
      this.showSpinner=false;
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  windowNotif(mesaje,mesaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mesaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
  }


  visualizarFoto(e){
    // console.log(e)
    switch(e){
      case 0:
        this.strImgVisualizar="data:image/gif;base64," + this.huesped.strBase64Foto1;
        break;

      case 1:
        this.strImgVisualizar="data:image/gif;base64," + this.huesped.strBase64Foto2;
        break;
    }
  }

}

export interface listSexos {
  strClave:any;
  strValor:any; 
}

export interface listDodumentos {
  intTipo:any;
  strNombre:any; 
}