export class HotelReserva {
    
    public intId?:number;
    public intGrupoId?:number;
    public intAgrupadoId?:number;
    public strEstado?:string;
    public intHabitacionId?:number;
    public datEntrada?:Date;
    public datSalida?:Date;
    public strTipoHabitacion?:string;
    public strTipoHabitaciones?:string;
    public intPax?:number;
    public intPaxR?:number;
    public intKids?:number;
    public datFechaReserva?:Date;
    public strLocalizador?:string;
    public intClienteId?:number;
    public strCliente?:string;
    public strNif?:string;
    public intAgenciaId?:number;
    public strAgencia?:string;
    public strClienteTelefono?:string;
    public strClienteEmail?:string;
    public strFacturarAgencia?:string;
    public intGastosAgencia?:number;
    public decGastos?:number;
    public strEmpresaId?:string;
    public strEmpresa?:string;
    public strFacturarA?:string;
    public intComisionistaId?:number;
    public strComisionista?:string;
    public strObservaciones?:string;
    public strCerrada?:string;
    public strTemporada?:string;
    public strRegimen?:string;
    public decPrecio?:number;
    public decIva?:number;
    public decDescuento?:number;
    public decFacturado?:number;
    public intGrupo?:number;
    public intDias?:number;
    public decBaseHabitaciones?:number;
    public decCuotaHabitaciones?:number;
    public decDtoHabitaciones?:number;
    public decBaseGastos?:number;
    public decCuotaGastos?:number;
    public intDocId?:number;
    public strBono?:string;
    public strFichaIncompleta?:string;
    public strCreditCard?:string;
    public strCreditName?:string;
    public strCreditCaduc?:string;
    public strAvisar?:string;
    public strOcultaPrecios?:string;
    public strUltUsuario?:string;
    public strTpvOpen?:string;
    //public intReservaExtId?:number;
    public strWebCerrada?:string;
    public intWebTipo?:number;
    public intWebAgrupadoPax?:number;
    public strWebAgrupadoTipoHabitacion?:string;
    public decWebAgrupadoImporte?:number;
    
}
