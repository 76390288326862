import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { Cliente } from 'src/app/models/cliente';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, DateAdapter, MatDatepickerInputEvent } from '@angular/material';
import { DatePipe } from '@angular/common';
import { RestService } from 'src/app/services/rest.service';
import { environment } from 'src/environments/environment';
import { FotoComponent } from '../foto/foto.component';

@Component({
  selector: 'app-alta-huesped',
  templateUrl: './alta-huesped.component.html',
  styleUrls: ['./alta-huesped.component.scss']
})
export class AltaHuespedComponent implements OnInit {
  huesped:Cliente=new Cliente();
  formNewHuesped: FormGroup;

  mensajeError:string;
  showSpinner=true;
  existError=false;

  submitted=false;
  dniValido=false;
  dniError=false;
  strTituloFormulario:string;
  miFechaNacimientoNueva:any;
  miFechaExpedidoNueva:any;

  btnInsert=false;
  btnEdit=false;
  listTipoSexo:listSexos[]=[];
  listTipoDocumento:listDodumentos[]=[];

  showButtons=true;

  constructor(private thisDialogRef: MatDialogRef<MatDialog>, @Inject(MAT_DIALOG_DATA) private data: any,private _formBuilder: FormBuilder,public datepipe: DatePipe,
              private miservicio:RestService,private dateAdapter: DateAdapter<Date>,private dialog: MatDialog
              ) {
                this.dateAdapter.setLocale('es-ES'); 
               }
               
  // constructor(private _formBuilder: FormBuilder,public datepipe: DatePipe,
  //             private miservicio:RestService,private dateAdapter: DateAdapter<Date>,private dialog: MatDialog
  //           ) {
  //               this.dateAdapter.setLocale('es-ES'); 
  //             }

  ngOnInit() {
    this.listTipoSexo.push({strClave:"M", strValor:"Masculino"}, {strClave:"F", strValor:"Femenino"});
    this.listTipoDocumento.push({intTipo:1, strNombre:"N.I.F."}, {intTipo:2, strNombre:"N.I.E"}, {intTipo:3, strNombre:"Pasaporte"});

    this.formNewHuesped = this._formBuilder.group({
      nombre: [Validators.required],
      apellido1: [Validators.required],
      apellido2: [Validators.required],
      sexo: [Validators.required],
      fNacimiento: ['',Validators.required],
      documento:[Validators.required],
      nif: [Validators.required],
      fexpedido: [Validators.required],
      pais: [Validators.required],
      provincia: [Validators.required],
      cp: [Validators.required]
    })
    // console.log(this.data)
    if(this.data.id!=undefined){
      //Edicion
      this.verDatosHuesped();
      this.huesped.intIdCliente=this.data.id;
      this.btnEdit=true;
      if(this.data.admin){this.showButtons=false; this.btnEdit=false; this.btnInsert=false;}
    }
    else{
      this.showSpinner=false;
      this.btnInsert=true;
      //Insercion
      this.strTituloFormulario="Nuevo huésped";
      this.huesped.intIdReserva=this.data.reserva;
    }
  }

  verDatosHuesped(){
    this.miservicio.getClientes(this.data.coo,0,this.data.reserva,this.data.id,"",environment.reservaHotelPC).subscribe(datos=>{
      this.showSpinner=false;
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.huesped=datos.miListReservasClientes[0];
        this.strTituloFormulario="Huésped " + datos.miListReservasClientes[0].strNombre + " " + datos.miListReservasClientes[0].strApellido1 + " " + datos.miListReservasClientes[0].strApellido2;
      }
    })
  }

  addEvent( event: MatDatepickerInputEvent<Date>) {
    this.miFechaNacimientoNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.huesped.datFechaNacimiento=this.miFechaNacimientoNueva;
  }

  addEvent2( event: MatDatepickerInputEvent<Date>) {
    this.miFechaExpedidoNueva=this.datepipe.transform(event.value, 'yyyy-MM-dd');
    this.huesped.datFechaDniExpedido=this.miFechaExpedidoNueva;
  }

  btnInsertar(){
    this.submitted=true;
    
    if(this.formNewHuesped.valid){
      // console.log(this.huesped)
      this.showSpinner=true;
      switch(this.huesped.intTipoDocumento){
        case 3:
          //No valido 
          this.dniValido=true;
          this.dniError=false;
          break;

        default:
          this.nif();
          break;
      }
      if(this.dniValido){
        this.miservicio.postClientes(this.data.coo,this.huesped).subscribe(datos=>{
          this.showSpinner=false;
          if(datos.miRespuesta.booOk){
            this.thisDialogRef.close(datos.miListReservasClientes);
            this.existError=false;
          }
          else{
            this.mensajeError=datos.miRespuesta.strMensaje;
            this.existError=true;
          }
        })
      }
    }
  }

  btnEditar(){
    this.submitted=true;

    if(this.formNewHuesped.valid){
      this.showSpinner=true;
      switch(this.huesped.intTipoDocumento){
        case 3:
          //No valido 
          this.dniValido=true;
          this.dniError=false;
          break;

        default:
          this.nif();
          break;
      }

      if(this.dniValido){
        this.miservicio.putClientes(this.data.coo,this.huesped).subscribe(datos=>{
          // console.log(datos)
          this.showSpinner=false;
          if(datos.miRespuesta.booOk){
            this.thisDialogRef.close(datos.miListReservasClientes);
            this.existError=false;
          }
          else{
            this.mensajeError=datos.miRespuesta.strMensaje;
            this.existError=true;
          }
        })
      }
    }
  }

  abrirCamara(){
    let dialogRef = this.dialog.open(FotoComponent, {
      width:'600px',
      data:"foto1"
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.huesped.strBase64Foto1=result;
      }      
    })
  }

  abrirCamara2(){
    let dialogRef = this.dialog.open(FotoComponent, {
      width:'600px',
      data:"foto2"
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        this.huesped.strBase64Foto2=result;
      }      
    })
  }

  nif() {
    var numero
    var letr
    var letra
  
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
   
    if(expresion_regular_dni.test (this.formNewHuesped.controls.nif.value) == true){
        numero = this.formNewHuesped.controls.nif.value.substr(0,this.formNewHuesped.controls.nif.value.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letr = this.formNewHuesped.controls.nif.value.substr(this.formNewHuesped.controls.nif.value.length-1,1);
        numero = numero % 23;
        letra='TRWAGMYFPDXBNJZSQVHLCKET';
        letra=letra.substring(numero,numero+1);
      if (letra!=letr.toUpperCase()) {
        this.dniError=true;
        return this.dniValido=false;
         //alert('Dni erroneo, la letra del NIF no se corresponde');
       }else{
         this.dniError=false;
         return this.dniValido=true;
       }
    }else{
      this.dniError=true;
      return this.dniValido=false;
       //alert('Dni erroneo, formato no válido');
     }
  }

  cancelar(){
    this.thisDialogRef.close();
  }
}


export interface listSexos {
  strClave:any;
  strValor:any; 
}

export interface listDodumentos {
  intTipo:any;
  strNombre:any; 
}