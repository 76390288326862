export class CookieReserva {

    public cookieP?:string;
    public idReserva?:number;
    public logo?:any;
    public nombre?:string;
    public descripcion?:string;
    public eslogan?:string;
    public email?:string;
    public telefono1?:string;
    public telefono2?:string;
    public direccion?:string;
    public cp?:string;
    public poblacion?:string;
    public provincia?:string;
    public pais?:string;
    public condicionesGenerales?:string;
    public politicaCancelaciones?:string;
    public politicaCookies?:string;
    public politicaPrivacidad?:string;
    public booReservaCerrada?:boolean;
    public strPrecioCheckin?:string;
    //CAROL 17/09/21
    public strMuestraTipoHabitacion?:string;

}
