import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Cliente } from 'src/app/models/cliente';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CookieReserva } from 'src/app/models/cookie-reserva';
import { HotelReserva } from 'src/app/models/hotel-reserva';
import { Funciones } from 'src/app/resources/Funciones';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'src/app/services/rest.service';
import { VentanaNotificacionComponent } from '../ventana-notificacion/ventana-notificacion.component';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  // listClientesCheckin:Cliente[]=[];
  data:Cliente[]=[];
  dataTodo:Cliente[]=[];
  listFiltrada:Cliente[]=[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  displayedColumns = ['intId','strNombre','strApellido1','strDni','strSexo'];
  dataSource;
  filtro:any;

  teclaAlt:any;
  otraTecla:any;

  // dataExcel:datosCliente[]=[];

  cookieCheckinOnline=environment.cookieCheckinOnline;
  misDatosCookieReserva:CookieReserva=new CookieReserva();

  idEst:any;
  miReserva:HotelReserva=new HotelReserva();
  strLogo:string;
  showSpinner = true;

  booReservaAbierta=false;
  miFunciones:Funciones=new Funciones();

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  videoWidth = 0;
  videoHeight = 0;

  constraints = {
    video: {
        facingMode: "environment",
        width: { ideal: 4096 },
        height: { ideal: 2160 }
    }
  };

  strNumRegistros:string="0 registros";

  constructor(private dialog: MatDialog,private router:Router,private cookieService: CookieService,private route: ActivatedRoute,private miservicio:RestService,private renderer: Renderer2) {
    this.route.queryParams.subscribe(params=>{
      this.idEst=params["id"];
    })
   }

  ngOnInit() {
    // this.startCamera();
    let est=this.miFunciones.decryptData(this.idEst);
    // console.log(est)
    let IsCookieExists:boolean=this.cookieService.check(this.cookieCheckinOnline+est);
    if(IsCookieExists){
      //Recupero los datos de la cookie
      this.misDatosCookieReserva=JSON.parse(this.cookieService.get(this.cookieCheckinOnline+est))

      // console.log(this.misDatosCookieReserva)
      //Doy formato
      // if(this.misDatosCookieReserva.telefono1!=null && this.misDatosCookieReserva.telefono1!=""){
      //   this.misDatosCookieReserva.telefono1=" - " + this.misDatosCookieReserva.telefono1;
      // }
      // if(this.misDatosCookieReserva.telefono2!=null && this.misDatosCookieReserva.telefono2!=""){
      //   this.misDatosCookieReserva.telefono2=" - " + this.misDatosCookieReserva.telefono2;
      // }
      // if(this.misDatosCookieReserva.direccion!=null && this.misDatosCookieReserva.direccion!=""){
      //   this.misDatosCookieReserva.direccion=" - " + this.misDatosCookieReserva.direccion;
      // }
      // if(this.misDatosCookieReserva.poblacion!=null && this.misDatosCookieReserva.poblacion!=""){
      //   this.misDatosCookieReserva.poblacion=" - " + this.misDatosCookieReserva.poblacion;
      // }
      // if(this.misDatosCookieReserva.cp!=null && this.misDatosCookieReserva.cp!=""){
      //   this.misDatosCookieReserva.cp=" - " + this.misDatosCookieReserva.cp;
      // }
      // if(this.misDatosCookieReserva.provincia!=null && this.misDatosCookieReserva.provincia!=""){
      //   this.misDatosCookieReserva.provincia=" - " + this.misDatosCookieReserva.provincia;
      // }

      // if(this.misDatosCookieReserva.booReservaCerrada){this.booReservaAbierta=false;}

      this.recuperoDatos()
    }else{
      this.windowNotif("Se ha producido un error.","Por favor vuelva a solicitar la URL.",false,true,false);
    }

    // this.dataSource=new MatTableDataSource(this.listClientesCheckin);
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator=this.paginator;
  }

  recuperoDatos(){
    this.miservicio.getClientes(this.misDatosCookieReserva.cookieP,0,this.misDatosCookieReserva.idReserva,0,"",environment.reservaHotelPC).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // this.booReservaAbierta=true;
        if(datos.miListReservasClientes.length>0){
          if(datos.miListReservasClientes[0].strReservaWebCerrada === "S"){this.booReservaAbierta=false;}else{this.booReservaAbierta=true;}
        }
        
        this.data=datos.miListReservasClientes;
        // console.log(datos)
        this.dataSource=new MatTableDataSource(datos.miListReservasClientes);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.strNumRegistros=datos.miListReservasClientes.length + " registros"
      }
      else{
        // console.log(this.misDatosCookieReserva.booReservaCerrada)
        if(this.misDatosCookieReserva.booReservaCerrada){this.booReservaAbierta=false;}else{this.booReservaAbierta=true;}
      }
      // else{
      //   // this.booReservaAbierta=false;
      //   this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false);
      // }
      this.recuperoLogo();
    })
  }

  recuperoLogo(){
    this.miservicio.getLogo(this.misDatosCookieReserva.cookieP).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner = false;
      this.strLogo=datos.strValor;
      // console.log(this.strLogo)
    })
  }

  // nextTap(c){
  //   // console.log(this.selected.value)
  //   if(this.selected.value<this.listClientesCheckin.length-1){
  //     this.selected.setValue(this.selected.value + 1);
  //   }
    
  //   // console.log(c)
  // }

  // previousTap(c){
  //   this.selected.setValue(this.selected.value - 1);
  //   // console.log(c)
  // }

  nuevo(){
    // let dialogRef = this.dialog.open(AltaHuespedComponent, {
    //   width:'600px',
    //   data:{
    //     "coo":this.misDatosCookieReserva.cookieP,
    //     "reserva":this.misDatosCookieReserva.idReserva,
    //     "admin":false
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result =>{
    //   if(result!=undefined){
    //     // console.log(result)

    //     if(result.length!=0){      
    //       this.data.splice(0, this.data.length);
    //       this.data=result;
    //     }
    //     this.dataSource=new MatTableDataSource(this.data);
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator=this.paginator;
    //     this.strNumRegistros=this.data.length + " registros"
    //   }      
    // })
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEst
      }
    }
    
    this.router.navigate(['formulario-huesped'], extra)
  }

  edita(e){
    // let dialogRef = this.dialog.open(AltaHuespedComponent, {
    //   width:'600px',
    //   data:{
    //     "id":e.intIdCliente,
    //     "coo":this.misDatosCookieReserva.cookieP,
    //     "reserva":e.intIdReserva,
    //     "admin":false
    //   }
    // });

    // dialogRef.afterClosed().subscribe(result =>{
    //   // console.log(result)
    //   if(result!=undefined){
    //     // console.log(result)
    //     if(result.length!=0){      
    //       this.data.splice(0, this.data.length);
    //       this.data=result;
    //     }
    //     this.dataSource=new MatTableDataSource(this.data);
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator=this.paginator;
    //     this.strNumRegistros=this.data.length + " registros"
    //   }      
    // })

    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEst,
        'data':e.intIdCliente
      }
    }
    
    this.router.navigate(['formulario-huesped'], extra)
  }

  busquedaGeneral() {
    this.dataTodo=this.data;

    if(this.filtro!=""){
      // console.log(this.filtro)
      this.data=this.data.filter(i=>
                                    i.strNombre.toLowerCase().includes(this.filtro.toLowerCase()) ||
                                    i.strApellido1.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strApellido2.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strDni.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strTelefono1.toString().includes(this.filtro.toString()) ||
                                    i.strTelefono2.toString().toLowerCase().includes(this.filtro.toString().toLowerCase()) ||
                                    i.strEmail.toString().toLowerCase().includes(this.filtro.toString().toLowerCase())
      );
    }

    // if(this.miFiltro!=null){
    //   // console.log(this.miFiltro)
    //   this.data=this.data.filter(i=>                                    
    //                                 i.strNombre.toLowerCase().includes(this.miFiltro.strNombre.toLowerCase()) && 
    //                                 i.strApellidos.toLowerCase().includes(this.miFiltro.strApellidos.toLowerCase()) && 
    //                                 i.strDni.toString().includes(this.miFiltro.strDni.toString())
    //   );
    // }

    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.strNumRegistros=this.data.length + " registros"
    
    this.data=this.dataTodo;        
  }

  confirmaReserva(){
    // console.log(this.data.length)
    //verifico si hay huespedes asignados en la reserva
    //de lo contrario no dejo finalizar el checkin online
    if(this.data.length>0){
      let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
        width: '600px',
        data:{
          texto:"¿Esta seguro de confirmar la reserva?",
          texto2:"Asegurese de que todos los datos son correctos.",
          boton:true,
          btnError:false,
          btnOK:false
        }
      });
      dialogRef.afterClosed().subscribe(result=>{
        if(result!=undefined){
          this.showSpinner=true;
          this.miservicio.putCheckinOnline(this.misDatosCookieReserva.cookieP,this.misDatosCookieReserva.idReserva).subscribe(datos=>{
            // console.log(datos)
            this.showSpinner=false;
            if(datos.booOk){
              //Vuelvo a guardar los datos de la cookie con el campo booReservaCerrada=true
              this.misDatosCookieReserva.booReservaCerrada=true;
              this.removeCookies();
              this.cookieService.set(this.cookieCheckinOnline+this.miFunciones.decryptData(this.idEst),JSON.stringify(this.misDatosCookieReserva),4,'',window.location.hostname);

              this.windowNotif(datos.strMensaje,"", false, false, true)
              this.booReservaAbierta=false;

              //le redirijo al resumen
              let extra: NavigationExtras = {
                queryParams: {
                  'id':this.idEst
                }
              }
              this.router.navigate(['inicio/resumen-reserva'], extra)
            }else{
              this.windowNotif(datos.strMensaje,"", false, true, false)
            }
          })
        }
      })
    }else{
      this.windowNotif("No se ha proporcionado ningún huésped a esta reserva.", "No puede realizar el checkin online.", false, true, false)
    }
  }

  removeCookies(){
    this.cookieService.delete(this.cookieCheckinOnline+this.miFunciones.decryptData(this.idEst),'',window.location.hostname);
  }

  // startCamera() {
  //   if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
  //       navigator.mediaDevices.getUserMedia(this.constraints).then(this.attachVideo.bind(this)).catch(this.handleError);
  //   } else {
  //       alert('Sorry, camera not available.');
  //   }
  // }

  // attachVideo(stream) {
  //     this.renderer.setProperty(this.videoElement.nativeElement, 'srcObject', stream);
  //     this.renderer.listen(this.videoElement.nativeElement, 'play', (event) => {
  //         this.videoHeight = this.videoElement.nativeElement.videoHeight;
  //         this.videoWidth = this.videoElement.nativeElement.videoWidth;
  //     });
  // }

  // capture() {
  //     this.renderer.setProperty(this.canvas.nativeElement, 'width', this.videoWidth);
  //     this.renderer.setProperty(this.canvas.nativeElement, 'height', this.videoHeight);
  //     this.canvas.nativeElement.getContext('2d').drawImage(this.videoElement.nativeElement, 0, 0);
  // }

  // handleError(error) {
  //     console.log('Error: ', error);
  // }

  windowNotif(mesaje,mesaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mesaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
  }

}
