import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AltaHuespedComponent } from './components/alta-huesped/alta-huesped.component';
import { CameraComponent } from './components/camera/camera.component';
import { CheckinOnlineComponent } from './components/checkin-online/checkin-online.component';
import { DataReservaComponent } from './components/data-reserva/data-reserva.component';
import { FormularioHuespedComponent } from './components/formulario-huesped/formulario-huesped.component';
import { FormularioComponent } from './components/formulario/formulario.component';
import { FotoComponent } from './components/foto/foto.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ResumenReservaComponent } from './components/resumen-reserva/resumen-reserva.component';
import { TakePictureComponent } from './components/take-picture/take-picture.component';
import { VentanaNotificacionComponent } from './components/ventana-notificacion/ventana-notificacion.component';


const routes: Routes = [
  { path: 'inicio/resumen-reserva', component: ResumenReservaComponent },
  { path: 'inicio/formulario', component: FormularioComponent },
  { path: 'checkin-online', component: CheckinOnlineComponent },
  { path: 'ventana-notificacion', component: VentanaNotificacionComponent },
  { path: 'huesped', component: AltaHuespedComponent },
  { path: 'camera', component: CameraComponent },
  { path: 'foto', component: FotoComponent },
  { path: 'formulario-huesped', component: FormularioHuespedComponent },
  { path: 'admin/data-reserva', component: DataReservaComponent },
  { path: 'picture', component: TakePictureComponent },
  { path: 'progress', component: ProgressComponent },
  
  {path: '', redirectTo:'/inicio/resumen-reserva', pathMatch:'full'},
  {path: '**', redirectTo:'/inicio/resumen-reserva'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
