import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RestService } from './services/rest.service';
import { AppMaterialModule } from './app-material.module';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatPaginatorIntl } from '@angular/material';
import { CustomPaginator } from './resources/Funciones';
import { DatePipe } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ResumenReservaComponent } from './components/resumen-reserva/resumen-reserva.component';
import { FormularioComponent } from './components/formulario/formulario.component';
import { CheckinOnlineComponent } from './components/checkin-online/checkin-online.component';
import { VentanaNotificacionComponent } from './components/ventana-notificacion/ventana-notificacion.component';
import { AltaHuespedComponent } from './components/alta-huesped/alta-huesped.component';
import { CameraComponent } from './components/camera/camera.component';
import { FotoComponent } from './components/foto/foto.component';
import { FormularioHuespedComponent } from './components/formulario-huesped/formulario-huesped.component';
import { DataReservaComponent } from './components/data-reserva/data-reserva.component';
import { CookieService } from 'ngx-cookie-service';
import { TakePictureComponent } from './components/take-picture/take-picture.component';
import { AppRoutingModule } from './app-routing.module';
import { ProgressComponent } from './components/progress/progress.component';
import { NgxImageCompressService } from 'ngx-image-compress';

// const appRoutes:Routes=[
//   {path: '', redirectTo:'/inicio/resumen-reserva', pathMatch:'full'},
//   // { path: '**', redirectTo: '/inicio/resumen-reserva', pathMatch: 'full' },
//   { path: 'inicio/resumen-reserva', component: ResumenReservaComponent },
//   { path: 'inicio/formulario', component: FormularioComponent },
//   { path: 'checkin-online', component: CheckinOnlineComponent },
//   { path: 'ventana-notificacion', component: VentanaNotificacionComponent },
//   { path: 'huesped', component: AltaHuespedComponent },
//   { path: 'camera', component: CameraComponent },
//   { path: 'foto', component: FotoComponent },
//   { path: 'formulario-huesped', component: FormularioHuespedComponent },
//   { path: 'admin/data-reserva', component: DataReservaComponent },
// ]; 

@NgModule({
  declarations: [
    AppComponent,
    ResumenReservaComponent,
    FormularioComponent,
    CheckinOnlineComponent,
    VentanaNotificacionComponent,
    AltaHuespedComponent,
    CameraComponent,
    FotoComponent,
    DataReservaComponent,
    FormularioHuespedComponent,
    TakePictureComponent,
    ProgressComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule,
    // HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    // RouterModule.forRoot(
    //   appRoutes,
    //   {enableTracing:true}
    // )
  ],
  providers: [RestService, {provide: MatPaginatorIntl, useValue: CustomPaginator() },DatePipe, CookieService,NgxImageCompressService],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
