import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Cliente } from 'src/app/models/cliente';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CookieReserva } from 'src/app/models/cookie-reserva';
import { HotelReserva } from 'src/app/models/hotel-reserva';
import { Funciones } from 'src/app/resources/Funciones';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'src/app/services/rest.service';
import { AltaHuespedComponent } from '../alta-huesped/alta-huesped.component';
import { VentanaNotificacionComponent } from '../ventana-notificacion/ventana-notificacion.component';

@Component({
  selector: 'app-data-reserva',
  templateUrl: './data-reserva.component.html',
  styleUrls: ['./data-reserva.component.scss']
})
export class DataReservaComponent implements OnInit {

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  data:Cliente[]=[];
  dataTodo:Cliente[]=[];
  listFiltrada:Cliente[]=[];

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  displayedColumns = ['intId','strNombre','strApellido1','strDni','strSexo'];
  dataSource;
  filtro:any;

  teclaAlt:any;
  otraTecla:any;

  // dataExcel:datosCliente[]=[];

  cookieCheckinOnline=environment.cookieCheckinOnline;
  misDatosCookieReserva:CookieReserva=new CookieReserva();

  idEst:any;
  miReserva:HotelReserva=new HotelReserva();
  strLogo:string;
  showSpinner = true;

  booReservaCerrada=true;
  miFunciones:Funciones=new Funciones();

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  videoWidth = 0;
  videoHeight = 0;

  constraints = {
    video: {
        facingMode: "environment",
        width: { ideal: 4096 },
        height: { ideal: 2160 }
    }
  };

  strNumRegistros:string="0 registros";

  constructor(private dialog: MatDialog,private router:Router,private cookieService: CookieService,private route: ActivatedRoute,private miservicio:RestService,private renderer: Renderer2) {
    this.route.queryParams.subscribe(params=>{
      this.idEst=params["id"];
    })
   }

  ngOnInit() {
    let est=this.miFunciones.decryptData(this.idEst);
    let IsCookieExists:boolean=this.cookieService.check(this.cookieCheckinOnline+est);
    if(IsCookieExists){
      //Recupero los datos de la cookie
      this.misDatosCookieReserva=JSON.parse(this.cookieService.get(this.cookieCheckinOnline+est))

      //Doy formato
      if(this.misDatosCookieReserva.telefono1!=null && this.misDatosCookieReserva.telefono1!=""){
        this.misDatosCookieReserva.telefono1=" - " + this.misDatosCookieReserva.telefono1;
      }
      if(this.misDatosCookieReserva.telefono2!=null && this.misDatosCookieReserva.telefono2!=""){
        this.misDatosCookieReserva.telefono2=" - " + this.misDatosCookieReserva.telefono2;
      }
      if(this.misDatosCookieReserva.direccion!=null && this.misDatosCookieReserva.direccion!=""){
        this.misDatosCookieReserva.direccion=" - " + this.misDatosCookieReserva.direccion;
      }
      if(this.misDatosCookieReserva.poblacion!=null && this.misDatosCookieReserva.poblacion!=""){
        this.misDatosCookieReserva.poblacion=" - " + this.misDatosCookieReserva.poblacion;
      }
      if(this.misDatosCookieReserva.cp!=null && this.misDatosCookieReserva.cp!=""){
        this.misDatosCookieReserva.cp=" - " + this.misDatosCookieReserva.cp;
      }
      if(this.misDatosCookieReserva.provincia!=null && this.misDatosCookieReserva.provincia!=""){
        this.misDatosCookieReserva.provincia=" - " + this.misDatosCookieReserva.provincia;
      }

      this.recuperoDatos()
      this.recuperaReserva()
    }else{
      this.windowNotif("Se ha producido un error.","Por favor vuelva a solicitar la URL.",false,true,false);
    }
  }

  recuperaReserva(){
    this.miservicio.getReserva(this.misDatosCookieReserva.cookieP,this.misDatosCookieReserva.idReserva,"",0,"",environment.reservaHotelPC).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.booReservaCerrada=true;
        this.miReserva=datos.miListReservas[0];
        // console.log(this.miReserva)
        this.recuperoLogo();
      }else{
        this.booReservaCerrada=false;
        this.showSpinner = false;
        this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false)
      }
    })
  }

  recuperoDatos(){
    this.miservicio.getClientes(this.misDatosCookieReserva.cookieP,0,this.misDatosCookieReserva.idReserva,0,"",environment.reservaHotelPC).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.booReservaCerrada=true;
        this.data=datos.miListReservasClientes;
        // console.log(datos)
        this.dataSource=new MatTableDataSource(datos.miListReservasClientes);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.strNumRegistros=datos.miListReservasClientes.length + " registros"
      }else{
        // this.booReservaCerrada=false;
        this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false);
      }
      this.recuperoLogo();
    })
  }

  recuperoLogo(){
    this.miservicio.getLogo(this.misDatosCookieReserva.cookieP).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner = false;
      this.strLogo=datos.strValor;
    })
  }

  nuevo(){
    let dialogRef = this.dialog.open(AltaHuespedComponent, {
      width:'600px',
      data:{
        "coo":this.misDatosCookieReserva.cookieP,
        "reserva":this.misDatosCookieReserva.idReserva
      }
    });

    dialogRef.afterClosed().subscribe(result =>{
      if(result!=undefined){
        // console.log(result)

        if(result.length!=0){      
          this.data.splice(0, this.data.length);
          this.data=result;
        }
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.strNumRegistros=this.data.length + " registros"
      }      
    })
  }

  edita(e){
    let dialogRef = this.dialog.open(AltaHuespedComponent, {
      width:'600px',
      data:{
        "id":e.intIdCliente,
        "coo":this.misDatosCookieReserva.cookieP,
        "reserva":e.intIdReserva,
        "admin":true
      }
    });

    dialogRef.afterClosed().subscribe(result =>{
      // console.log(result)
      if(result!=undefined){
        // console.log(result)
        if(result.length!=0){      
          this.data.splice(0, this.data.length);
          this.data=result;
        }
        this.dataSource=new MatTableDataSource(this.data);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        this.strNumRegistros=this.data.length + " registros"
      }      
    })
  }

  busquedaGeneral() {
    this.dataTodo=this.data;

    if(this.filtro!=""){
      // console.log(this.filtro)
      this.data=this.data.filter(i=>
                                    i.strNombre.toLowerCase().includes(this.filtro.toLowerCase()) ||
                                    i.strApellido1.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strApellido2.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strDni.toLowerCase().includes(this.filtro.toLowerCase()) || 
                                    i.strTelefono1.toString().includes(this.filtro.toString()) ||
                                    i.strTelefono2.toString().toLowerCase().includes(this.filtro.toString().toLowerCase()) ||
                                    i.strEmail.toString().toLowerCase().includes(this.filtro.toString().toLowerCase())
      );
    }

    this.dataSource=new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator=this.paginator;
    this.strNumRegistros=this.data.length + " registros"
    
    this.data=this.dataTodo;        
  }

  confirmaReserva(){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:"¿Esta seguro de confirmar la reserva?",
        texto2:"Asegurese de que todos los datos son correctos.",
        boton:true,
        btnError:false,
        btnOK:false
      }
    });
    dialogRef.afterClosed().subscribe(result=>{
      if(result!=undefined){
        this.showSpinner=true;
        this.miservicio.putCheckinOnline(this.misDatosCookieReserva.cookieP,this.misDatosCookieReserva.idReserva).subscribe(datos=>{
          // console.log(datos)
          this.showSpinner=false;
          if(datos.booOk){
            this.windowNotif("Se ha realizado correctamente el checkin online.", "", false, false, true)
          }else{
            this.windowNotif("Se ha producido un error.", "Por favor inténtelo mas tarde.", false, true, false)
          }
        })
      }
    })
  }

  windowNotif(mesaje,mesaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mesaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
  }

}
