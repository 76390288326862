export class Cliente {

    /*
    public intId?:number;
    public strNombre?:string;
    public strApellidos?:string;
    public strDni?:string;
    public strEmail?:string;
    public strDireccion?:string;
    public strCP?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strActivo?:string;
    public strUserId?:string;
    public strTipoCliente?:string;
    public strPassword?:string;
    public strUserName?:string;

    //lo uso para filtros
    constructor(nombre?:string,apellidos?:string,dni?:string,email?:string,pass?:string){
        this.strNombre=nombre;
        this.strApellidos=apellidos;
        this.strDni=dni;
        this.strEmail=email;
        this.strPassword=pass;
    }
    */

   public intId?:number;
   public intIdReserva?:number;
   public intIdCliente?:number;
   public strNombre?:string;
   public strApellido1?:string;
   public strApellido2?:string;
   public strDni?:string;
   public datFechaDniExpedido?:Date;
   public strTelefono1?:string;
   public strTelefono2?:string;
   public strEmail?:string;
   public strDireccion?:string;
   public strPoblacion?:string;
   public strCp?:string;
   public strProvincia?:string;
   public strPais?:string;
   public strSexo?:string;
   public datFechaNacimiento?:Date;
   public intTipoDocumento?:number;
   public strBase64Foto1?:string;
   public strBase64Foto2?:string;
   public strReservaWebCerrada?:string;

}
