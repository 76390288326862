import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'AppFront';

  ngOnInit(){
    if (location.protocol === 'http:'){
      // window.location.href = location.href.replace('http', 'https');
    }
  }
  
}
