import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieReserva } from 'src/app/models/cookie-reserva';
import { HotelReserva } from 'src/app/models/hotel-reserva';
import { Funciones } from 'src/app/resources/Funciones';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { RestService } from 'src/app/services/rest.service';
import { VentanaNotificacionComponent } from '../ventana-notificacion/ventana-notificacion.component';
import { Cliente } from 'src/app/models/cliente';

@Component({
  selector: 'app-resumen-reserva',
  templateUrl: './resumen-reserva.component.html',
  styleUrls: ['./resumen-reserva.component.scss']
})
export class ResumenReservaComponent implements OnInit {

  checked=false;

  cookieCheckinOnline=environment.cookieCheckinOnline;
  misDatosCookieReserva:CookieReserva=new CookieReserva();

  idEst:any;
  miReserva:HotelReserva=new HotelReserva();
  strLogo:string;
  showSpinner = true;
  // booReservaCerrada=true;
  booMuestraBoton=true;
  miFunciones:Funciones=new Funciones();

  data:Cliente[]=[];
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  displayedColumns = ['intId','strNombre','strApellido1','strDni','strSexo'];
  dataSource;

  constructor(private dialog: MatDialog,private router:Router,private cookieService: CookieService,private route: ActivatedRoute,private miservicio:RestService) {
    this.route.queryParams.subscribe(params=>{
      this.idEst=params["id"];
    })
   }

  ngOnInit() {
    let est=this.miFunciones.decryptData(this.idEst);
    let IsCookieExists:boolean=this.cookieService.check(this.cookieCheckinOnline+est);
    if(IsCookieExists){
      //Recupero los datos de la cookie
      this.misDatosCookieReserva=JSON.parse(this.cookieService.get(this.cookieCheckinOnline+est))

      // console.log(this.misDatosCookieReserva.strPrecioCheckin)

      //Doy formato
      if(this.misDatosCookieReserva.telefono1!=null && this.misDatosCookieReserva.telefono1!=""){
        this.misDatosCookieReserva.telefono1=" - " + this.misDatosCookieReserva.telefono1;
      }
      if(this.misDatosCookieReserva.telefono2!=null && this.misDatosCookieReserva.telefono2!=""){
        this.misDatosCookieReserva.telefono2=" - " + this.misDatosCookieReserva.telefono2;
      }
      if(this.misDatosCookieReserva.direccion!=null && this.misDatosCookieReserva.direccion!=""){
        this.misDatosCookieReserva.direccion=" - " + this.misDatosCookieReserva.direccion;
      }
      if(this.misDatosCookieReserva.poblacion!=null && this.misDatosCookieReserva.poblacion!=""){
        this.misDatosCookieReserva.poblacion=" - " + this.misDatosCookieReserva.poblacion;
      }
      if(this.misDatosCookieReserva.cp!=null && this.misDatosCookieReserva.cp!=""){
        this.misDatosCookieReserva.cp=" - " + this.misDatosCookieReserva.cp;
      }
      if(this.misDatosCookieReserva.provincia!=null && this.misDatosCookieReserva.provincia!=""){
        this.misDatosCookieReserva.provincia=" - " + this.misDatosCookieReserva.provincia;
      }

      if(this.misDatosCookieReserva.booReservaCerrada){this.booMuestraBoton=false; this.recuperoClientes()}

      this.recuperaReserva()
    }else{
      this.windowNotif("Se ha producido un error.","Por favor vuelva a solicitar la URL.",false,true,false);
    }
  }

  edita(e){
    let extra: NavigationExtras = {
      queryParams: {
        'id':this.idEst,
        'data':e.intIdCliente
      }
    }
    
    this.router.navigate(['formulario-huesped'], extra)
  }

  recuperaReserva(){
    // console.log(this.misDatosCookieReserva.booReservaCerrada)
    this.miservicio.getReserva(this.misDatosCookieReserva.cookieP,this.misDatosCookieReserva.idReserva,"",0,"",environment.reservaHotelPC).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        // this.booReservaCerrada=true;
        this.miReserva=datos.miListReservas[0];
        // console.log(this.miReserva)
        this.recuperoLogo();
      }else{
        // this.booReservaCerrada=false;
        this.showSpinner = false;
        this.windowNotif(datos.miRespuesta.strMensaje,"",false,true,false)
      }
    })
  }

  recuperoClientes(){
    this.miservicio.getClientes(this.misDatosCookieReserva.cookieP,0,this.misDatosCookieReserva.idReserva,0,"",environment.reservaHotelPC).subscribe(datos=>{
      // console.log(datos)
      if(datos.miRespuesta.booOk){
        this.data=datos.miListReservasClientes;
        this.dataSource=new MatTableDataSource(datos.miListReservasClientes);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator=this.paginator;
        // this.strNumRegistros=datos.miListReservasClientes.length + " registros"
      }
    })
  }

  recuperoLogo(){
    this.miservicio.getLogo(this.misDatosCookieReserva.cookieP).subscribe(datos=>{
      // console.log(datos)
      this.showSpinner = false;
      this.strLogo=datos.strValor;
      // console.log(this.strLogo)
    })
  }

  verCondGenerales(){
    this.windowNotif("Términos y condiciones generales de la reserva.",this.misDatosCookieReserva.condicionesGenerales,false,false,false);
  }

  verPoliticaPrivacidad(){
    this.windowNotif("Política de privacidad.",this.misDatosCookieReserva.politicaPrivacidad,false,false,false);
  }

  verCondReserva(){
    this.windowNotif("Condiciones de reserva.",this.misDatosCookieReserva.politicaCancelaciones,false,false,false);
  }

  ckeckin(){
    if(!this.checked){
      this.windowNotif("Antes debe aceptar los términos y condiciones generales de la reserva, política de privacidad y condiciones de reserva.","",false,true,false);
    }else{
      let extra: NavigationExtras = {
        queryParams: {
          'id':this.idEst
        }
      }
      this.router.navigate(['inicio/formulario'], extra)
    }
  }

  windowNotif(mesaje,mesaje2,boton,btnError,btnOk){
    let dialogRef = this.dialog.open(VentanaNotificacionComponent, {
      width: '600px',
      data:{
        texto:mesaje,
        texto2:mesaje2,
        boton:boton,
        btnError:btnError,
        btnOK:btnOk
      }
    });
  }

}
