export class Establecimiento {

    public intId?:number;
    public strNombre?:string;
    public strDescripcion?:string;
    public strNif?:string;
    public strEmail?:string;
    public strWeb?:string;
    public strTelefono1?:string;
    public strTelefono2?:string;
    public strDireccion?:string;
    public strCp?:string;
    public strPoblacion?:string;
    public strProvincia?:string;
    public strPais?:string;
    public strCondicionesGenerales?:string;
    public strMapa?:string;
    public strEslogan?:string;
    public strActivo?:string;
    public strUserId?:string;
    public strCorreoUsuario?:string;
    public strCorreoClave?:string;
    public strCorreoSmtp?:string;
    public strCorreoSmtpPort?:string;
    public strCorreoSmtpSsl?:string;
    public strUsaSsl?:string;
    public strUrlHost?:string;
    public strUrlCambioContrasena?:string;
    public intTipoDato?:number;
    public intHoraEntrada?:number;
    public intMinEntrada?:number;
    public intHoraSalida?:number;
    public intMinSalida?:number;
    public strMovHorizontal?:string;
    public strMovVertical?:string;
    public strPoliticaCancelaciones?:string;
    public strModificarEstancia?:string;
    public intIvaReservaDefecto?:number;
    public decIvaValor?:number;
    public strBorraReserva?:string;
    public strPoliticaPrivacidad?:string;
    public strPoliticaCookies?:string;
    public strLogo?:string;
    public strPrecioCheckin?:string;
    //CAROL 17/09/21
    public strMuestraTipoHabitacion?:string;

}
