import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-take-picture',
  templateUrl: './take-picture.component.html',
  styleUrls: ['./take-picture.component.scss']
})
export class TakePictureComponent implements AfterViewInit {

  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  WIDTH = 640;
  // HEIGHT = 480;
  HEIGHT = 450;
  error: any;
  isError=false;
  showSpinner=true;

  constructor(private renderer: Renderer2,private thisDialogRef: MatDialogRef<MatDialog>,@Inject(MAT_DIALOG_DATA) private data: any) { }

 async ngAfterViewInit() {
    await this.setupDevices();
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          // video: true
          video:{facingMode:'environment'}
        });
        if (stream) {
          // console.log('LLEGOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO')
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.play();
          this.error = null;
          this.showSpinner=false;
          this.isError=false;
        } else {
          this.error = "No se puede abrir la camara.";
          this.showSpinner=false;
          this.isError=true;
        }
      } catch (e) {
        // this.error = e;
        this.error = "No se puede abrir la camara.";
        this.showSpinner=false;
        this.isError=true;
      }
    }

    // console.log(this.isError)
  }

  capture2() {
    this.drawImageToCanvas(this.videoElement.nativeElement);
    // this.captures.push(this.canvas.nativeElement.toDataURL("image/png"));
    // console.log(this.canvas.nativeElement.toDataURL("image/png"))

    this.thisDialogRef.close(this.canvas.nativeElement.toDataURL("image/png"));
    // this.isCaptured = true;
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  close(){
    this.thisDialogRef.close();
  }

}
