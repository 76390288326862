import { MatPaginatorIntl } from '@angular/material';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

export class Funciones  {

    // constructor(private storageService:StorageService) { }

    DesencriptaUsuarioPassword(strDatoEncriptado: string): string {
        let strDatoDesencriptado: string = "";   
            
        if (strDatoEncriptado != null && strDatoEncriptado != "") {
            strDatoEncriptado = strDatoEncriptado.split('').reverse().join('');
            strDatoEncriptado = strDatoEncriptado.substr(environment.INT_PRE_SUF_SECURITY_STAMP, strDatoEncriptado.length - (2 * environment.INT_PRE_SUF_SECURITY_STAMP));
            
            let i: number;
            for (i = 0; i < strDatoEncriptado.length; i++) {
                if (i % 2 == 0) { strDatoDesencriptado += strDatoEncriptado.substr(i, 1); }
            }
        }
        return strDatoDesencriptado;
    }

    // EncriptaPassword(strPassword, strPasswordHash, strSecurityStamp)
    EncriptaPassword(strPassword, strPasswordHash, strSecurityStamp)
    {
        let strSecurityStampHash = "";
        let strPrefijo = strSecurityStamp.Substring(0, environment.INT_PRE_SUF_SECURITY_STAMP);
        let strSufijo = strSecurityStamp.Substring(strSecurityStamp.Length - environment.INT_PRE_SUF_SECURITY_STAMP);

        let i:number;
        for (i = 0; i < strPasswordHash.Length; i++)
        {
            if (i < strPassword.Length)
                strSecurityStampHash += strPassword.Substring(i, 1) + strPasswordHash.Substring(i, 1);
            else
                i = strPasswordHash.Length;
        }

        // Concateno con prefijo y sufijo
        strSecurityStampHash = strPrefijo + strSecurityStampHash + strSufijo;
        let dato = strSecurityStampHash.split('').reverse().join('');
        return strSecurityStampHash.split('').reverse().join('');
    }

    encryptData(data) {

        try {
          return CryptoJS.AES.encrypt(JSON.stringify(data), environment.ClaveSecreta).toString();
        } catch (e) {
          console.log(e);
        }
      }
    
      decryptData(data) {
    
        try {
          const bytes = CryptoJS.AES.decrypt(data, environment.ClaveSecreta);
          if (bytes.toString()) {
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          }
          return data;
        } catch (e) {
          console.log(e);
        }
      }

    nif(dni) {
        var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
        var str = dni.toUpperCase();
    
        if (!nifRexp.test(str) && !nieRexp.test(str)) return false;
    
        var nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');
    
        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;
    
        if (validChars.charAt(charIndex) === letter) return true;
    
        return false;
      }      
      
}

//Cambiar el label del elemento paginator de la tabla
export function CustomPaginator() {
    const customPaginatorIntl = new MatPaginatorIntl();
  
    customPaginatorIntl.itemsPerPageLabel = 'Items por pagina';
  
    return customPaginatorIntl;
}